import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"

export default function BlogPost({ data, pageContext: { previous, next }, path }) {
  const post = data.markdownRemark
  const justification =
    previous && !next
      ? "flex-end"
      : !previous && next
      ? "flex-start"
      : "space-between"
  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        cover={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        slug={path}
      />
      <Header />

      <div className="post-header" style={{ marginTop: "1rem" }}>
        <h1 className="h3">{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>
        {/* <p>{post.frontmatter.category}</p> */}
      </div>
      <div className="post-image">
        <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
      </div>
      <div className="post-content" style={{ marginTop: "1rem" }}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
      <div
        className="post-navigation"
        style={{ display: "flex", justifyContent: justification }}
      >
        {next && (
          <Link to={`/blog${next.fields.slug}`} rel="next">
            ← {next.frontmatter.title}
          </Link>
        )}
        {previous && (
          <Link to={`/blog${previous.fields.slug}`} rel="prev">
            {previous.frontmatter.title} →
          </Link>
        )}
      </div>
      <Footer />
    </>
  )
}

// is a page query so that $slug can be used
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        category
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
      timeToRead
      html
      wordCount {
        words
        sentences
        paragraphs
      }
    }
  }
`
